import React from 'react'
import { navigate } from 'gatsby-link'
import styled from 'styled-components'
import ContactImage from './images/contact-laptop'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default function Talk() {
  const [state, setState] = React.useState({})

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch(error => alert(error))
  }

  return (
    <PageContainer>
      <TextContainer>
        We'll get back to you ASAP. Call us if it's urgent 516.244.2916
      </TextContainer>
      <FormContainer>
        <Form
          name="contact"
          method="post"
          action="/talk/success"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={handleSubmit}
          autoComplete="off"
        >
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value="contact" />

          <Input
            type="text"
            name="name"
            id="name"
            required
            placeholder="Name"
            onChange={handleChange}
          />
          <Input
            type="text"
            name="email"
            id="email"
            required
            placeholder="Email Address"
            onChange={handleChange}
          />
          <Input
            type="text"
            name="phone"
            placeholder="Phone Number (optional)"
            onChange={handleChange}
          />
          <TextArea
            name="message"
            id="message"
            rows="6"
            placeholder="Notes"
            onChange={handleChange}
          />
          <ButtonContainer>
            <SendButton type="submit" value="Send Message" className="special">
              Send
            </SendButton>
          </ButtonContainer>
        </Form>
      </FormContainer>
      <ImageContainer>
        <ContactImage />
      </ImageContainer>
    </PageContainer>
  )
}

const PageContainer = styled.div`
  text-align: center;
  background: black;
  color: white;
  min-height: 100vh;
  max-width: 1200px;
  margin: 0px auto;
`
const Input = styled.input`
  display: block;
  width: 80%;
  color: white;
  background: black;
  margin: 0px auto 15px;
  padding: 10px;
  text-align: center;
  border-radius: 20px;

  @media screen and (min-width: 375px) {
    margin-bottom: 4%;
    font-size: calc(16px + (24 - 16) * ((100vw - 375px) / (1200 - 375)));
  }

  @media screen and (min-width: 1200px) {
    margin-bottom: 48px;
    font-size: 24px;
  }

  :focus {
    box-shadow: 0 0 0 1pt white;
    outline: none;
  }
`

const TextArea = styled.textarea`
  display: block;
  width: 80%;
  color: white;
  background: black;
  margin: 10px auto;
  padding: 10px;
  min-height: 100px;
  border-radius: 20px;

  ::placeholder {
    text-align: center;
  }

  :focus {
    box-shadow: 0 0 0 1pt white;
    outline: none;
  }

  @media screen and (min-width: 375px) {
    margin-bottom: 4%;
    font-size: calc(16px + (24 - 16) * ((100vw - 375px) / (1200 - 375)));
  }

  @media screen and (min-width: 1200px) {
    margin-bottom: 48px;
    font-size: 24px;
  }
`

const ImageContainer = styled.div`
  width: 70%;
  margin: 0 auto;
  padding-top: 10px;
  max-width: 600px;
`

const TextContainer = styled.div`
  font-size: 16px;
  width: 75%;
  margin: auto;
  padding: 25px 0px;
  font-family: 'Helvetica Neue';
  font-weight: 300;
  line-height: 1.3;

  @media screen and (min-width: 375px) {
    font-size: calc(16px + (35 - 16) * ((100vw - 375px) / (1200 - 375)));
  }

  @media screen and (min-width: 1200px) {
    font-size: 35px;
  }
`

const FormContainer = styled.div`
  font-family: 'Helvetica Neue';
  max-width: 800px;
  margin: 0px auto;
`

const Form = styled.form`
  margin: auto;
`

const ButtonContainer = styled.div`
  width: 100%;
  margin: 15px auto;
`
const SendButton = styled.button`
  color: black;
  border-radius: 20px;
  background: white;
  width: 80%;
  padding: 10px;
  box-sizing: content-box;
  border: none;
  :focus {
    box-shadow: 0 0 0 2pt white;
    outline: none;
  }

  :hover {
    cursor: pointer;
  }

  @media screen and (min-width: 375px) {
    font-size: calc(16px + (24 - 16) * ((100vw - 375px) / (1200 - 375)));
  }

  @media screen and (min-width: 1200px) {
    font-size: 24px;
  }
`
