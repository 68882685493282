import React from 'react'
import Talk from '../../components/talk/index'
import SEO from '../../components/seo'

const ContactPage = () => (
  <>
    <SEO title="Contact" />
    <Talk />
  </>
)

export default ContactPage
